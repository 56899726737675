import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { useSetRecoilState } from 'recoil';
import { sidebarAtom } from '../../../../atoms/Atoms';

function SidebarButton() {
  const setSidebar = useSetRecoilState(sidebarAtom);

  return (
    <button
      type="button"
      onClick={() => setSidebar(true)}
      className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
    >
      <span className="sr-only">Open sidebar</span>
      <FontAwesomeIcon icon={faBars} />
    </button>
  );
}

export default SidebarButton;

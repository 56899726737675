import React from 'react';
import ReactDOM from 'react-dom';
import { AzureAD } from 'react-aad-msal';
import { RecoilRoot } from 'recoil';
import './assets/index.css';
import App from './components/App';
import { authProvider } from './utils/authProvider';

ReactDOM.render(
  <AzureAD provider={authProvider} forceLogin>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </AzureAD>,
  document.getElementById('root')
);

import React from 'react';
import { useRecoilValueLoadable } from 'recoil';
import _ from 'lodash';
import { dataSelector } from '../../../../../../atoms/Selectors';
import Spinner from './Spinner';
import Table from './Table';

function Content() {
  const data = useRecoilValueLoadable(dataSelector);

  if (data.state === 'loading') {
    return <Spinner />;
  }

  if (data.state === 'hasValue') {
    const columns = _.map(Object.keys(data.contents.data[0]), (key) => ({
      accessor: key,
      Header: key,
    }));

    return (
      <div className="flex flex-col">
        <div className="rounded-lg overflow-x-hidden">
          <Table columns={columns} data={data.contents.data} />
        </div>
      </div>
    );
  }
}

export default Content;

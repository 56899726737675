import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMicrophoneAlt,
  faMicroscope,
  faVirus,
} from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import Logo from '../../../../assets/img/logo.svg';
import User from './User';

function Desktop() {
  return (
    <div className="hidden md:flex md:flex-shrink-0">
      <div className="flex flex-col w-64">
        <div className="flex flex-col h-0 flex-1">
          <div className="flex items-center space-x-2 h-16 flex-shrink-0 px-4 bg-lightBlue-600">
            <img className="h-8 w-auto" src={Logo} alt="Workflow" />
            <div className="text-white text-xs font-semibold">
              <div>Puerto Rico</div>
              <div>Public Health Trust</div>
            </div>
          </div>
          <div className="flex-1 flex flex-col overflow-y-auto p-4 bg-lightBlue-400">
            <nav className="text-sm flex-1 space-y-1 text-white">
              <div className="flex flex-col h-full w-full justify-between">
                <div className="flex flex-col">
                  <div className="flex items-center space-x-2 font-semibold tracking-tight py-2 px-4">
                    <FontAwesomeIcon icon={faVirus} className="leading-none" />
                    <div>CASPER Project</div>
                  </div>
                  <div className="px-4">
                    <NavLink
                      to="/"
                      exact
                      className="text-white text-xs font-semibold flex items-center space-x-2 p-2 rounded-md outline-none focus:outline-none"
                      activeClassName="bg-lightBlue-500 text-white"
                    >
                      <FontAwesomeIcon icon={faMicroscope} />
                      <div>Samples</div>
                    </NavLink>
                    <NavLink
                      to="/interviews"
                      className="text-white text-xs font-semibold flex items-center space-x-2 p-2 rounded-md outline-none focus:outline-none"
                      activeClassName="bg-lightBlue-500 text-white"
                    >
                      <FontAwesomeIcon icon={faMicrophoneAlt} />
                      <div>Interviews</div>
                    </NavLink>
                  </div>
                </div>
                <User />
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Desktop;

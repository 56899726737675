import React from 'react';

function Header() {
  return (
    <div className="flex space-x-4 items-center justify-between py-10">
      <div>
        <div className="text-3xl font-extrabold tracking-tight sm:truncate">
          CASPER Samples
        </div>
        <div className="text-sm text-gray-500 tracking-tight sm:truncate">
          Community Assessment for Public Health Emergency Response
        </div>
      </div>
    </div>
  );
}

export default Header;

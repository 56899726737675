import { selector } from 'recoil';
import axios from 'axios';
import { tokenAtom } from './Atoms';
import { authProvider } from '../utils/authProvider';

export const dataSelector = selector({
  key: 'data',
  get: async ({ get }) => {
    const token = get(tokenAtom);
    console.log(token);

    try {
      const response = axios({
        method: 'GET',
        url: '/api/casper',
      });
      return await response;
    } catch (error) {
      throw error;
    }
  },
});

export const interviewsSelector = selector({
  key: 'interviews',
  get: async ({ get }) => {
    const token = get(tokenAtom);
    console.log(token);

    try {
      const response = axios({
        method: 'GET',
        url: '/api/interviews',
      });
      return await response;
    } catch (error) {
      throw error;
    }
  },
});

export const accountSelector = selector({
  key: 'account',
  get: async ({ get }) => {
    const token = get(tokenAtom);

    if (token) {
      try {
        const response = authProvider.getAccountInfo();
        return await response;
      } catch (error) {
        throw error;
      }
    }
  },
});

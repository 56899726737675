import React from 'react';
import Desktop from './shared-components/Desktop';
import Mobile from './shared-components/Mobile';

function Sidebar() {
  return (
    <>
      <Mobile />
      <Desktop />
    </>
  );
}

export default Sidebar;

import { MsalAuthProvider, LoginType } from 'react-aad-msal';

const config = {
  auth: {
    authority:
      'https://login.microsoftonline.com/3906a315-ca10-4e64-83cb-50ea735f28eb',
    clientId: 'a088f7f6-5135-4bdb-bff6-23e73bc60654',
    postLogoutRedirectUri: window.location.origin,
    redirectUri: window.location.origin,
    validateAuthority: true,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
};

const authenticationParameters = {
  scopes: ['User.Read'],
};

const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: `${window.location.origin}/auth.html`,
};

export const authProvider = new MsalAuthProvider(
  config,
  authenticationParameters,
  options
);

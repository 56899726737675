import React from 'react';
import { useTable, usePagination, useGlobalFilter } from 'react-table';
import { Listbox, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import {
  faChevronLeft,
  faChevronRight,
  faDownload,
} from '@fortawesome/free-solid-svg-icons';

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setGlobalFilter,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  );

  const download = (obj) => {
    const items = obj;
    const replacer = (key, value) => (value === null ? '' : value);
    const header = Object.keys(items[0]);
    let csv = items.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(',')
    );
    csv.unshift(header.join(','));
    csv = csv.join('\r\n');

    const downloadLink = document.createElement('a');
    const blob = new Blob(['\ufeff', csv]);
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = `data-${new Date()}.csv`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <>
      <div className="grid grid-col-1 lg:grid-cols-2 gap-4 mb-4 text-xs">
        <div className="space-x-1 flex items-center">
          <button
            type="button"
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            className="h-10 font-semibold text-gray-700 rounded-md border border-gray-300 bg-white hover:bg-gray-100 px-3 py-2 focus:outline-none focus:outline-none transition ease-in-out duration-150"
          >
            First Page
          </button>
          <button
            type="button"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="h-10 font-semibold text-gray-700 rounded-md border border-gray-300 bg-white hover:bg-gray-100 px-3 py-2 focus:outline-none focus:outline-none transition ease-in-out duration-150"
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <button
            type="button"
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className="h-10 font-semibold text-gray-700 rounded-md border border-gray-300 bg-white hover:bg-gray-100 px-3 py-2 focus:outline-none focus:outline-none transition ease-in-out duration-150"
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
          <button
            type="button"
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
            className="h-10 font-semibold text-gray-700 rounded-md border border-gray-300 bg-white hover:bg-gray-100 px-3 py-2 focus:outline-none focus:outline-none transition ease-in-out duration-150"
          >
            Last Page
          </button>
          <span>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
        </div>
        <div className="space-x-1 flex items-center justify-start lg:justify-end">
          <div>
            <input
              type="search"
              value={globalFilter || ''}
              onChange={(e) => setGlobalFilter(e.target.value)}
              placeholder="Search..."
              className="ring-0 focus:ring-0 h-10 border-gray-300 focus:border-gray-300 rounded-md text-xs"
            />
          </div>
          <Listbox
            as="div"
            className="space-y-1 w-32 outline-none focus:outline-none ring-0 focus:ring-0"
            value={pageSize}
            onChange={setPageSize}
          >
            {({ open }) => (
              <div className="relative outline-none focus:outline-none ring-0 focus:ring-0">
                <Listbox.Button className="h-10 text-left w-full rounded-md font-semibold text-gray-700 rounded-md border border-gray-300 bg-white hover:bg-gray-100 px-3 py-2 outline-none focus:outline-none ring-0 focus:ring-0 transition ease-in-out duration-150">
                  <span className="block truncate">Show {pageSize}</span>
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <svg
                      className="h-5 w-5 text-gray-400"
                      viewBox="0 0 20 20"
                      fill="none"
                      stroke="currentColor"
                    >
                      <path
                        d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </Listbox.Button>
                <Transition
                  show={open}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  className="absolute mt-1 w-full rounded-md bg-white shadow-lg outline-none focus:outline-none ring-0 focus:ring-0"
                >
                  <Listbox.Options
                    static
                    className="max-h-60 rounded-md py-1 text-base leading-6 shadow-xs overflow-auto outline-none focus:outline-none ring-0 focus:ring-0 sm:text-sm sm:leading-5"
                  >
                    {_.map([10, 20, 30, 40, 50], (size) => (
                      <Listbox.Option
                        key={size}
                        value={size}
                        className="outline-none focus:outline-none ring-0 focus:ring-0"
                      >
                        {({ selected, active }) => (
                          <div
                            className={`${
                              active ? 'bg-gray-100' : 'text-gray-900'
                            } cursor-pointer select-none relative py-2 pl-8 pr-4 text-xs`}
                          >
                            <span
                              className={`${
                                selected ? 'font-semibold' : 'font-normal'
                              } block truncate`}
                            >
                              Show {size}
                            </span>
                            {selected && (
                              <span
                                className={`${
                                  active ? 'text-gray-900' : 'text-rose-500'
                                } absolute inset-y-0 left-0 flex items-center pl-1.5`}
                              >
                                <svg
                                  className="h-5 w-5"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </span>
                            )}
                          </div>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            )}
          </Listbox>
          <button
            type="button"
            onClick={() => download(data)}
            className="h-10 flex space-x-2 items-center font-semibold text-white rounded-md bg-rose-500 hover:bg-rose-600 px-3 py-2 focus:outline-none focus:outline-none transition ease-in-out duration-150"
          >
            <FontAwesomeIcon icon={faDownload} />
            <div>Download</div>
          </button>
        </div>
      </div>

      <div className="col-span-2 rounded-lg overflow-auto">
        <table {...getTableProps()} className="table-fixed text-sm divide-y">
          <thead className="bg-gray-200">
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="whitespace-nowrap text-center"
              >
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    className="font-semibold text-xs p-4"
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="text-center divide-y">
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className="bg-white divide-x divide-y"
                >
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      className="whitespace-nowrap text-xs font-normal p-4"
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default Table;

import { atom } from 'recoil';

export const sidebarAtom = atom({
  key: 'sidebar',
  default: false,
});

export const searchAtom = atom({
  key: 'search',
  default: '',
});

export const tokenAtom = atom({
  key: 'token',
  default: null,
});

import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useMount } from 'react-use';
import { faVirus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRecoilState } from 'recoil';
import Sidebar from './shared-components/sidebar/Sidebar';
import Header from './shared-components/header/Header';
import Samples from './pages/casper/pages/samples/Samples';
import { authProvider } from '../utils/authProvider';
import { tokenAtom } from '../atoms/Atoms';
import Interviews from './pages/casper/pages/interviews/Interviews';

function App() {
  const [token, setToken] = useRecoilState(tokenAtom);

  useMount(async () => {
    const accessToken = await authProvider.getAccessToken();
    if (accessToken) {
      setToken(accessToken);
    }
  });

  if (!token) {
    return (
      <div className="h-screen flex flex-col text-xs items-center space-y-2 justify-center bg-lightBlue-400 space-y-1 text-white">
        <FontAwesomeIcon icon={faVirus} spin className="text-2xl" />
        <div>Acquiring Credentials...</div>
      </div>
    );
  }

  return (
    <Router>
      <div className="h-screen flex overflow-hidden bg-gray-100 antialiased">
        <Sidebar />
        <div className="flex flex-col w-0 flex-1 overflow-hidden">
          <Header />
          <main className="flex-1 relative overflow-y-auto">
            <Switch>
              <Route exact path="/">
                <Samples />
              </Route>
              <Route path="/interviews">
                <Interviews />
              </Route>
            </Switch>
          </main>
        </div>
      </div>
    </Router>
  );
}

export default App;

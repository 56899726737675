import React from 'react';
import Header from './shared-components/Header';
import Content from './shared-components/Content';

function Samples() {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <Header />
      <Content />
    </div>
  );
}

export default Samples;

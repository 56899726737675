import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVirus } from '@fortawesome/free-solid-svg-icons';

function Spinner() {
  return (
    <div className="flex flex-1 items-center justify-center text-xs text-gray-500">
      <div className="flex flex-col items-center space-y-2">
        <FontAwesomeIcon
          icon={faVirus}
          spin
          className="text-cyan-500 text-opacity-75 text-2xl"
        />
        <div>Loading Dataset</div>
      </div>
    </div>
  );
}

export default Spinner;

import React from 'react';
import { Menu } from '@headlessui/react';
import { useRecoilValueLoadable } from 'recoil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { authProvider } from '../../../../utils/authProvider';
import { accountSelector } from '../../../../atoms/Selectors';

function Navbar() {
  const data = useRecoilValueLoadable(accountSelector);

  if (data.state === 'hasValue') {
    return (
      <div className="ml-4 flex items-center md:ml-6">
        <div className="ml-3 relative">
          <Menu>
            <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none">
              <FontAwesomeIcon
                icon={faUserCircle}
                className="text-3xl text-gray-400"
              />
            </Menu.Button>
            <Menu.Items className="bg-black divide-y origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-xl bg-white outline-none focus:outline-none">
              <Menu.Item className="block px-4 py-2 text-sm text-gray-400 outline-none focus:outline-none">
                <div>
                  <div className="text-xs truncate">
                    {data.contents && data.contents.account.name}
                  </div>
                  <div className="text-xs truncate">
                    {data.contents && data.contents.account.userName}
                  </div>
                </div>
              </Menu.Item>
              <Menu.Item
                onClick={() => authProvider.logout()}
                className="mb-0 m-0 text-center block px-4 py-2 text-xs text-rose-500 hover:text-rose-600 font-medium cursor-pointer outline-none focus:outline-none"
              >
                <div>Cerrar Sesión</div>
              </Menu.Item>
            </Menu.Items>
          </Menu>
        </div>
      </div>
    );
  }

  return null;
}

export default Navbar;

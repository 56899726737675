import React from 'react';
import SidebarButton from './shared-components/SidebarButton';
import Navbar from './shared-components/Navbar';

function Header() {
  return (
    <div className="relative z-10 flex-shrink-0 flex h-16 bg-white shadow">
      <SidebarButton />
      <div className="flex-1 px-4 flex items-center justify-end">
        <Navbar />
      </div>
    </div>
  );
}

export default Header;

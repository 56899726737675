import React from 'react';
import { Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMicrophoneAlt,
  faMicroscope,
  faTimes,
  faVirus,
} from '@fortawesome/free-solid-svg-icons';
import { useRecoilState } from 'recoil';
import { NavLink } from 'react-router-dom';
import { sidebarAtom } from '../../../../atoms/Atoms';
import Logo from '../../../../assets/img/logo.svg';
import User from './User';

function Mobile() {
  const [sidebar, setSidebar] = useRecoilState(sidebarAtom);

  return (
    <div className="md:hidden">
      <Transition show={sidebar} className="fixed inset-0 flex z-40">
        <Transition.Child
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className="fixed inset-0"
        >
          <div className="absolute inset-0 bg-gray-600 opacity-75" />
        </Transition.Child>
        <Transition.Child
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
          className="relative flex-1 flex flex-col max-w-xs w-full"
        >
          <div className="absolute top-0 right-0 -mr-12 pt-2">
            <button
              type="button"
              onClick={() => setSidebar(false)}
              className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            >
              <span className="sr-only">Close Sidebar</span>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div className="flex items-center space-x-2 h-16 flex-shrink-0 px-4 bg-lightBlue-600">
            <img className="h-8 w-auto" src={Logo} alt="Workflow" />
            <div className="text-white text-xs font-semibold">
              <div>Puerto Rico</div>
              <div>Public Health Trust</div>
            </div>
          </div>
          <div className="bg-lightBlue-400 flex-1 flex flex-col overflow-y-auto p-4">
            <nav className="text-sm flex-1 bg-lightBlue-400 space-y-1 text-white">
              <div className="flex flex-col h-full w-full justify-between">
                <div className="flex flex-col">
                  <div className="flex items-center space-x-2 font-semibold tracking-tight py-2 px-4">
                    <FontAwesomeIcon icon={faVirus} className="leading-none" />
                    <div>CASPER Project</div>
                  </div>
                  <div className="px-4">
                    <NavLink
                      to="/"
                      exact
                      className="text-white text-xs font-semibold flex items-center space-x-2 p-2 rounded-md outline-none focus:outline-none"
                      activeClassName="bg-lightBlue-500 text-white"
                    >
                      <FontAwesomeIcon icon={faMicroscope} />
                      <div>Samples</div>
                    </NavLink>
                    <NavLink
                      to="/interviews"
                      className="text-white text-xs font-semibold flex items-center space-x-2 p-2 rounded-md outline-none focus:outline-none"
                      activeClassName="bg-lightBlue-500 text-white"
                    >
                      <FontAwesomeIcon icon={faMicrophoneAlt} />
                      <div>Interviews</div>
                    </NavLink>
                  </div>
                </div>
                <User />
              </div>
            </nav>
          </div>
        </Transition.Child>
      </Transition>
    </div>
  );
}

export default Mobile;

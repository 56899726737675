import React from 'react';
import { useRecoilValueLoadable } from 'recoil';
import { accountSelector } from '../../../../atoms/Selectors';

function User() {
  const account = useRecoilValueLoadable(accountSelector);

  if (account.state === 'hasValue') {
    return (
      <div className="p-4 font-semibold text-xs">
        <div>{account.contents.account.name}</div>
        <div>{account.contents.account.userName}</div>
      </div>
    );
  }

  return null;
}

export default User;
